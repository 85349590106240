"use client";
// import { useUser } from "@supabase/auth-helpers-react";
import { BookOpen, Check, Plus, Settings } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useParams, useRouter } from "next/navigation";
import React, { useContext, useEffect, useState, type FC } from "react";
import { toast } from "react-toastify";
import { Balancer } from "react-wrap-balancer";
import removeMd from "remove-markdown";
import { StackedArticleLoading } from "@src/component/loading";
import { ArticleActions } from "@src/component/miniComponent";
import { CommentsModal } from "@src/component/popup";
import { CountCommentsOnPost } from "@src/lib/supabase/post";
import { extractUsername, setUserFollowingById } from "@src/lib/supabase/userFollowers";
// import { FollowContext } from "@src/pages/u/[username]/[slug]";
import type { Article, User } from "@src/types";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";
import { api } from "@src/utils/api";
import { formatDate } from "@src/utils/miniFunctions";
import { UserInterface } from "@src/utils/types";
import { FollowContext } from "@src/component/pages/singleArticle";
import { supabase } from "@src/utils/supabase2";

const ArticleBody = ({ article }: { article: any }) => {
  // console.log("🚀 ~ ArticleBody ~ article:", article)

  const [commentsModal, setCommentsModal] = useState(false);
  const [commentsCount, setCommentsCount] = useState<any[]>([]);
  const [stories, setStories] = useState<any[]>([]);
  // const { mutate } = api.posts.read.useMutation();
  const query = useParams();
  const router = useRouter();
  const username = query?.username

  const fetchData = async () => {
    const resData = await CountCommentsOnPost(article.id, '');
    setCommentsCount(resData);
  }


  const fetchStories = async () => {
    let { data: stories, error } = await supabase
      .from('articles')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(3)
    setStories(stories || []);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchData(); fetchStories() }, []);


  useEffect(() => {
    if (commentsModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [commentsModal]);

  return (
    <main className="bg-white pb-12 dark:bg-primary">
      <div className="mx-auto max-w-[1000px]">
        {article?.featuredImage?.sourceUrl && (
          <Image
            src={article?.featuredImage?.sourceUrl}
            alt={article?.title}
            width={1200}
            height={800}
            draggable={false}
            className="w-full md:w-10/12 lg:w-full mx-auto overflow-hidden md:rounded-b-md object-cover"
          />
        )}

        <section className={`relative pt-8 md:pb-0 md:pt-14`}>
          <div className="px-4">
            <div className="flex items-center w-full justify-center">
              <Balancer className="mx-auto block text-center mb-6 text-2xl sm:text-3xl font-bold leading-snug text-gray-700 dark:text-text-secondary w-full md:mb-8 md:text-5xl md:leading-tight">
                {article.title}
              </Balancer>
            </div>

            {/* {article?.subtitle && (
              <div className="flex items-center w-full justify-center">
                <Balancer className="mx-auto mb-5 break-words text-center text-xl font-normal text-gray-600 dark:text-text-primary md:mb-10 md:text-3xl">
                  {article?.subtitle}
                </Balancer>
              </div>
            )} */}

            <div className="mx-auto mb-6 flex w-full flex-col items-center justify-center gap-2 md:mb-10 md:w-fit lg:flex-row">
              <Link
                aria-label="Visit image"
                className="mb-10 flex items-center gap-2 lg:mb-0"
                href={`/author/${username}`}
              >
                <Image
                  src={typeof article?.author?.avatar === 'object' ? article?.author?.avatar.url : article?.author?.avatar || "/default_user.avif"}
                  alt={article?.author?.name}
                  width={70}
                  height={70}
                  draggable={false}
                  className="h-8 w-8 overflow-hidden rounded-full object-cover"
                />

                <h1 className="text-xl font-semibold text-gray-700 dark:text-text-secondary">
                  {article?.author?.name}
                </h1>
              </Link>

              <div className="flex w-full items-center justify-between gap-2 lg:w-fit">
                <span className="hidden text-gray-900 dark:text-text-secondary lg:block">
                  ·
                </span>
                <h3 className="text-base font-medium text-gray-700 dark:text-text-primary md:text-lg">
                  {formatDate(new Date(article.date))}
                </h3>
                <span className="hidden text-gray-900 dark:text-text-secondary lg:block">
                  ·
                </span>
                <div className="flex items-center gap-2">
                  <BookOpen className="h-5 w-5 stroke-gray-700 dark:stroke-text-secondary" />
                  {/* <span className="text-base text-gray-700 dark:text-text-primary md:text-lg">
                    {article.read_time} min read
                  </span> */}
                </div>
              </div>
            </div>

            <div
              dangerouslySetInnerHTML={{ __html: article.content || "" }}
              className="article mx-auto w-full break-words text-gray-700 dark:text-text-secondary pb-10 pt-2 sm:pt-6 md:py-6"
            />
          </div>

          <ArticleActions
            article={article}
            setCommentsModal={setCommentsModal}
            commentsCount={commentsCount}
          />

          <ArticleTags tags={article.tags} />

          <div className="flex flex-wrap md:flex-nowrap gap-10 w-[100%] p-4">
            {stories.map((story, index) => (
              <div key={index} className="rounded-md border border-border-light bg-white p-4 dark:border-border dark:bg-primary md:w-[30%] w-[100%]">
                <Link href={`/news/${story.id}`}>
                  <h5 className="max-height-three mb-2 font-semibold text-gray-700 dark:text-text-secondary mb-3">
                    {story.title}
                  </h5>
                  <p className="max-height-four break-words text-sm text-gray-500 dark:text-text-primary">
                    {story.summary}
                  </p>
                </Link>
              </div>
            ))}
          </div>


          {article.author && <ArticleAuthor author={article.author as UserInterface} />}

          {commentsModal && (
            <CommentsModal
              id={article.id}
              commentsModal={commentsModal}
              authorUsername={article.author.username}
              setCommentsModal={setCommentsModal}
            />
          )}
          {/*
          {article.series && (
            <SeriesSection series={article.series} slug={article.slug} />
          )} */}
        </section>
      </div>
    </main>
  );
};

export default ArticleBody;

// const SeriesSection: FC<{
//   slug: string;
//   series: { title: string; slug: string };
// }> = ({ series, slug }) => {
//   const { data: user } = useSession();
//   const username = useRouter().query.username as string;

//   const { data, isLoading } = api.series.getSeriesOfArticle.useQuery({
//     slug: series.slug,
//   }, {
//     refetchOnWindowFocus: false,
//     retry: 0,
//   });

//   return (
//     <div className="px-4 py-16">
//       <div className="mx-auto max-w-[1000px] rounded-md border border-border-light dark:border-border">
//         <header className="border-b border-border-light px-6 py-4 dark:border-border">
//           <h1 className="mb-1 text-sm font-bold text-gray-500 dark:text-text-primary">
//             ARTICLE SERIES
//           </h1>

//           {isLoading ? (
//             <>
//               <StackedArticleLoading />
//               <StackedArticleLoading />
//               <StackedArticleLoading />
//               <StackedArticleLoading />
//               <StackedArticleLoading />
//             </>
//           ) : (
//             data && (
//               <span className="text-lg font-bold text-secondary hover:underline">
//                 <Link
//                   href={`/dev/@${user?.user?.handle?.handle as string
//                     }/series/${data.slug}`}
//                 >
//                   {data.title}
//                 </Link>
//               </span>
//             )
//           )}
//         </header>

//         <main className="">
//           {data &&
//             data.articles.map((article, index) => (
//               <div
//                 className="flex flex-col gap-4 border-b border-border-light p-4 last:border-none dark:border-border md:flex-row md:items-center"
//                 key={article.id}
//               >
//                 <div
//                   className={`flex  h-10 w-10 items-center justify-center rounded-full ${article.slug === slug
//                     ? "bg-secondary text-white"
//                     : "bg-slate-200 text-primary"
//                     }`}
//                 >
//                   <h1 className="text-lg font-black">{index + 1}</h1>
//                 </div>

//                 <div className="flex flex-col md:flex-row items-center gap-2 md:gap-8 flex-1">
//                   <div className="flex flex-1 items-center gap-4">
//                     <Link target="_blank" href={`/u/${username}/${article.slug}` as string}>
//                       <h1 className="max-height-two mb-1 text-xl font-bold text-gray-700 dark:text-text-secondary">
//                         {article.title}
//                       </h1>

//                       <p className={`max-height-two mb-2 text-base text-gray-500 dark:text-text-primary ${article?.cover_image ? "" : "w-[95%]"}`}>
//                         {removeMd(article.content)}
//                       </p>
//                     </Link>
//                   </div>

//                   {article?.cover_image && (
//                     <Link
//                       target="_blank"
//                       className="w-full md:w-1/4"
//                       href={`/u/${username}/${article.slug}` as string}
//                     >
//                       <Image
//                         src={article.cover_image}
//                         alt={article.title}
//                         width={1200}
//                         height={800}
//                         draggable={false}
//                         className="w-full overflow-hidden rounded-md border border-border-light object-cover dark:border-border"
//                       />
//                     </Link>
//                   )}
//                 </div>
//               </div>
//             ))}
//         </main>
//       </div>
//     </div>
//   );
// };

// Define the type for a single tag
type Tag = {
  id: string;
  name: string;
  uri: string;
};

// Define the type for the props
type ArticleTagsProps = {
  tags: Tag[];
};


const ArticleTags = ({ tags }: ArticleTagsProps) => {
  return (
    <div className="mx-auto my-10 flex w-11/12 flex-wrap items-center justify-center gap-2 lg:w-8/12">
      {tags.map((tag) => (
        <Link href={tag?.uri} key={tag.id}>
          <span className="block rounded-md border border-border-light bg-light-bg px-4 py-2 text-sm font-medium text-gray-700 hover:shadow-md dark:border-border dark:bg-primary-light dark:text-text-secondary dark:hover:bg-border">
            {tag.name}
          </span>
        </Link>
      ))}
    </div>
  );
};

export const ArticleAuthor: FC<{ author: UserInterface }> = ({ author }) => {

  const { user } = useSupabaseAuth();
  const { following, setFollowing } = useContext(FollowContext) as {
    following: boolean;
    setFollowing: React.Dispatch<React.SetStateAction<boolean>>;
  }

  const followUser = () => {
    if (!user) {
      toast.error("You need to login to follow a user");
      return;
    }
    const username = user.username;
    const authorUsername = extractUsername(author.uri);

    if (authorUsername) {
      setUserFollowingById(username, authorUsername, !following);
    }
    setFollowing((prev) => !prev);
  };

  return (
    <div className="px-4">
      <div className="mx-auto mb-4 mt-10 w-full border-y border-border-light px-4 py-6 dark:border-border md:w-8/12">
        <div className="flex flex-1 items-start gap-4">
          <Link href={author?.uri || ''}>
            <Image
              src={typeof author?.avatar === 'object' ? author?.avatar.url : author?.avatar || "/default_user.avif"}
              alt={author.name}
              width={100}
              height={100}
              className="obejct-cover md:h-18 md:w-18 h-14 w-14 overflow-hidden rounded-full sm:h-16 sm:w-16 "
            />
          </Link>

          <div className={"flex-1"}>
            <div className="flex flex-col sm:flex-row w-full items-start justify-between">
              <div className="mb-3 sm:mb-0">
                <h2 className="text-uppercase mb-1 text-sm font-medium text-gray-600 dark:text-text-primary">
                  WRITTEN BY
                </h2>

                <Link href={author?.uri}>
                  <h1 className="text-uppercase text-lg font-semibold text-gray-800 dark:text-text-secondary">
                    {author?.name}
                  </h1>
                </Link>
              </div>

              {user?.name === author.name ? (
                <Link href={`/dashboard/general`}>
                  <button className="btn-filled flex w-full items-center justify-center gap-2 text-secondary md:w-max">
                    <Settings className="h-5 w-5 stroke-white fill-none" />
                    Dashboard
                  </button>
                </Link>
              ) : (
                <button
                  onClick={() => void followUser()}
                  className="btn-outline hidden w-max items-center justify-center gap-2 text-secondary sm:flex"
                >
                  {following ? (
                    <>
                      <Check className="h-5 w-5 stroke-secondary" />
                      <span>Following</span>
                    </>
                  ) : (
                    <>
                      <Plus className="h-5 w-5 stroke-secondary" />
                      <span>Follow User</span>
                    </>
                  )}
                </button>
              )}
            </div>

            {author?.description && (
              <div className="mt-2 hidden sm:mt-4 sm:block">
                <p className="text-base text-gray-600 dark:text-text-primary">
                  {author.description}
                </p>
              </div>
            )}
          </div>
        </div>
        {author?.description && (
          <div className="mt-4 block sm:mt-4 sm:hidden">
            <p className="text-base text-gray-600 dark:text-text-primary">
              {author.description}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
