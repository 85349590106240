import { supabase } from "../supabaseClient";

export const IsTagFollowByUsername = async (username: string, tagUri: string) => {
    let { data: user_tags, error } = await supabase
        .from('user_tags')
        .select("*")
        .eq('username', username)
        .eq('tag_uri', tagUri)
    return user_tags;
}

export const setTagFollowingByUri = async (username: string, tagUri: string, tagName: string, follow = true) => {
    try {
        const userFollow = await IsTagFollowByUsername(username, tagUri);
        if (userFollow && userFollow.length > 0) {
            if (!follow) {
                await DeleteTagFollowing(username, tagUri);
                console.log('Unfollowed successfully');
            } else {
                console.log('User is already being followed');
            }
        } else if (follow) {
            await InsertTagFollowing(username, tagUri, tagName);
            console.log('Followed successfully');
        }
    } catch (error) {
        console.error('Error in setUserFollowingById:', error);
    }
};

export const InsertTagFollowing = async (username: string, tagUri: string, tagName: string) => {
    const { data, error } = await supabase
        .from('user_tags')
        .insert([{ username, tag_name: tagName, tag_uri: tagUri }])
        .select()
    return data;
}

export const DeleteTagFollowing = async (username: string, tagUri: string) => {
    const { error } = await supabase
        .from('user_tags')
        .delete()
        .eq('username', username)
        .eq('tag_uri', tagUri)
}

export const GetTagFollowingByUri = async (tagUri: string) => {
    let { data: user_tags, error } = await supabase
        .from('user_tags')
        .select("*")
        .eq('tag_uri', tagUri)
    return user_tags;
}