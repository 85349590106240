"use client";
import type { NextPage } from "next";
import React, { createContext, useEffect } from "react";
import { ArticleBody, ArticleHeader, Footer } from "@src/component";
import { UserInterface } from "@src/utils/types";
import { extractUsername, IsUserFollowByUsername } from "@src/lib/supabase/userFollowers";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";
interface postDetails {
    author: UserInterface
}

interface Props {
    postDetails: postDetails
}

export const FollowContext = createContext<{
    following: boolean;
    setFollowing: React.Dispatch<React.SetStateAction<boolean>>;
}>({
    following: false,
    setFollowing: () => {
        // do nothing
    },
});

const SingleArticle: NextPage<Props> = ({ postDetails }) => {
    const [following, setFollowing] = React.useState(false);
    const { user } = useSupabaseAuth()

    const fetchData = async () => {
        if (!user) return null;
        const authorUsername = extractUsername(postDetails.author.uri);
        const followingData = await IsUserFollowByUsername(user?.username || '', authorUsername || '')
        if (followingData && followingData?.length > 0) {
            setFollowing(true)
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchData(); }, [user])

    return (
        <>
            <FollowContext.Provider value={{ following, setFollowing }} >
                <ArticleHeader user={postDetails.author} />
                <ArticleBody article={postDetails} />
            </FollowContext.Provider>
            <Footer />
        </>
    );
};

export default SingleArticle;