"use client";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Cookies from "js-cookie";
import { type NextPage } from "next";
import Image from "next/image";
import { useEffect, useState, type FC } from "react";
import { toast } from "react-toastify";
import AuthorBlog from "@src/SEO/AuthorBlog.seo";
import { AuthorBlogHeader, Footer, Grid, Magazine, Stacked } from "@src/component";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";
import { UserInterface } from "@src/utils/types";

export interface BlogSocial {
    twitter: string;
    mastodon: string;
    instagram: string;
    github: string;
    website: string;
    linkedin: string;
    youtube: string;
    dailydev: string;
}

export interface CustomTabs {
    id: string;
    label: string;
    type: string;
    value: string;
    priority: number;
    createdAt: Date;
    updatedAt: Date;
}


interface articleInterface {
    id: string;
    slug: string;
    title: string;
    content: string;
    cover_image: string | null;
    created_at: Date;
    read_time: number;
};

const AuthorBlogs = ({ user: author }: { user: UserInterface, }) => {

    const { user } = useSupabaseAuth();
    const supabase = useSupabaseClient()

    const [appearance, setAppearance] = useState<
        | {
            layout: "MAGAZINE" | "STACKED" | "GRID";
        }
        | undefined
    >(undefined);
    useEffect(() => {
        (async () => {
            const url = new URL(location.href);
            const access_token = url.searchParams.get('access_token');
            const refresh_token = url.searchParams.get('refresh_token');

            if (access_token) {
                await supabase.auth.setSession({ access_token: access_token || '', refresh_token: refresh_token || '' });
            }

            if (Cookies.get("access_token")) {
                await supabase.auth.setSession({ access_token: Cookies.get("access_token") || '', refresh_token: Cookies.get("refresh_token") || '' });
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isError, setIsError] = useState()
    const [data, setData] = useState<any[] | articleInterface[]>([])
    const [isLoading, setIsLoading] = useState(true)


    const fetchUserPosts = async () => {
        let { data: post, error } = await supabase
            .from('post')
            .select("*")
            .eq('user_id', author.user_id)
            .is("is_delete", null)
        setData(post || []);
        setIsLoading(false);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchUserPosts() }, [])


    useEffect(() => {
        if (isError) {
            toast.error("Something went wrong!");
        }
    }, [isError]);
    console.log("🚀 ~ AuthorBlogs ~ data:", data)

    return (
        <>
            <AuthorBlog author={user as any} />
            <AuthorBlogHeader user={author} />
            {
                {
                    MAGAZINE: (
                        <Magazine
                            author={author}
                            data={data}
                            isLoading={isLoading}
                        />
                    ),
                }["MAGAZINE"]
            }

            <Footer />
        </>
    );
};

export default AuthorBlogs;


export const AuthorArea: FC<{
    author: UserInterface
}> = ({ author }) => {
    return (
        <div className="bg-white dark:bg-primary">
            <div className="mx-auto flex max-w-[1000px] flex-col items-center justify-center px-4 py-14 md:py-16">
                <div className="flex flex-col items-center justify-center gap-2">
                    <Image
                        src={typeof author?.avatar === 'object' ? author?.avatar.url : author?.avatar || "/default_user.avif"}
                        width={120}
                        height={120}
                        alt="User image"
                        className="h-16 w-16 rounded-full object-cover md:h-20 md:w-20"
                    />
                    <h1 className="mb-4 text-2xl font-semibold text-gray-900 dark:text-text-secondary">
                        {author.name}
                    </h1>
                    <p className="text-center text-base text-gray-500 dark:text-text-primary">
                        {author.description || "No bio added yet!"}
                    </p>
                </div>
            </div>
        </div>
    );
};
