import { supabase } from "../supabaseClient";

export const IsUserFollowByUsername = async (username: string, follow_username: string) => {
    let { data: user_followers, error } = await supabase
        .from('user_followers')
        .select("*")
        .eq('username', username)
        .eq('follow_username', follow_username)
    return user_followers;
}

export const getUserFollowersByUsername = async (username: string) => {
    let { data: user_followers, error } = await supabase
        .from('user_followers')
        .select("*")
        .eq('follow_username', username)
    return user_followers;
}

export const getUserFollowingByusername = async (username: string) => {
    let { data: user_followers, error } = await supabase
        .from('user_followers')
        .select("*")
        .eq('username', username)
    return user_followers;
}

export const setUserFollowingById = async (username: string, followUsername: string, follow = true) => {
    try {
        const userFollow = await IsUserFollowByUsername(username, followUsername);

        if (userFollow && userFollow.length > 0) {
            if (!follow) {
                await DeleteUserFollowing(username, followUsername);
                console.log('Unfollowed successfully');
            } else {
                console.log('User is already being followed');
            }
        } else if (follow) {
            await InsertUserFollowing(username, followUsername);
            console.log('Followed successfully');
        }
    } catch (error) {
        console.error('Error in setUserFollowingById:', error);
    }
};

export const InsertUserFollowing = async (username: string, follow_username: string) => {
    const { data, error } = await supabase
        .from('user_followers')
        .insert([{ username, follow_username }])
        .select()
    return data;
}

export const DeleteUserFollowing = async (username: string, follow_username: string) => {
    const { error } = await supabase
        .from('user_followers')
        .delete()
        .eq('username', username)
        .eq('follow_username', follow_username)
}

export function extractUsername(username: string) {
    let usernameArr = username.toString().split('/');
    return usernameArr[usernameArr.length - 1];
}