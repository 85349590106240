import { useSession } from "next-auth/react";
import Link from "next/link";
import { type FC } from "react";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";


// const FILTER_TIME_OPTIONS_LABEL = {
//   any: "Any",
//   News: "News",
//   Entrepreneur: "entrepreneur-profile",
//   'Start-up': "start-up-entrepreneur",
//   'Small Business': "small-business-resources",
//   'Expert': "expert",
//   'Tech': "technology",
//   'Industry': 'industry'
// };


const ExploreMainComponentNavigation: FC<{
  slug: string | string[] | undefined;
}> = ({ slug }) => {
  const { user } = useSupabaseAuth();
  return (
    <header className="scroll-area overflow-auto border-b border-border-light px-4 dark:border-border">
      <div className="mx-auto flex w-max items-end justify-center gap-2">
        {[
          // {
          //   id: 123,
          //   name: "Trending",
          //   slug: "",
          // },
          // {
          //   id: 456,
          //   name: "Tags",
          //   slug: "tags",
          // },
          {
            id: 789,
            name: "News",
            slug: "news",
          },
          {
            id: 780,
            name: "Entrepreneur",
            slug: "entrepreneur-profile",
          },
          {
            id: 781,
            name: "Start-up",
            slug: "start-up-entrepreneur",
          },
          {
            id: 782,
            name: "Small Business",
            slug: "small-business-resources",
          },
          {
            id: 783,
            name: "Expert",
            slug: "expert",
          },
          {
            id: 784,
            name: "Tech",
            slug: "technology",
          },
          {
            id: 785,
            name: "Industry",
            slug: "industry",
          },
          // ...(user?.user_id
          //   ? [
          //     {
          //       id: 123541,
          //       name: "Tags you follow",
          //       slug: "tags-following",
          //     },
          //     {
          //       id: 5134523,
          //       name: "Articles you follow",
          //       slug: "articles-following",
          //     },
          //   ]
          //   : []),
        ].map((item) => (
          <Link href={`/explore/${item.slug}`} key={item.id}>
            <button
              className={`${slug === undefined
                ? item.slug === ""
                  ? "btn-tab-active"
                  : "btn-tab"
                : slug.includes(item.slug)
                  ? "btn-tab-active"
                  : "btn-tab"
                }`}
            >
              {item.name}
            </button>
          </Link>
        ))}
      </div>
    </header>
  );
};

export default ExploreMainComponentNavigation;
