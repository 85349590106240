
import { Calendar, Pencil } from "lucide-react";
// import { useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { type FC } from "react";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";
import { UserInterface } from "@src/utils/types";


const FollowimageArea: FC<{
  user: UserInterface
}> = ({ user }) => {
  const { user: session } = useSupabaseAuth();

  return <div className="w-full md:w-1/4 rounded-md border border-border-light bg-white p-6 dark:border-border dark:bg-primary">
    <Link href={`/author/${user?.username}`}>
      <Image
        src={user?.avatar as string}
        width={800}
        height={800}
        className="mb-4 h-28 w-28 overflow-hidden rounded-full object-cover"
        alt="User image"
      />
    </Link>

    <Link href={`/author/${user?.username}`}>
      <h1 className="mb-4 text-2xl font-semibold text-gray-700 dark:text-text-secondary">{user?.name}</h1>
    </Link>

    {session?.username === user?.username && (
      <Link href="/settings" className="btn-outline inline-flex mb-4 items-center gap-2">
        <Pencil className="h-5 w-5 fill-none stroke-secondary" />
        <span>Edit Profile</span>
      </Link>
    )}

    <div className="flex items-center gap-2">
      <span>
        <Calendar className="h-4 w-4 fill-none stroke-gray-500 dark:stroke-text-primary" />
      </span>

      <span className="text-md text-gray-500 dark:text-text-primary">
        Member since{" "}
        <span className="font-medium">
          {/* {new Date(user.).toDateString()} */}
        </span>
      </span>
    </div>
  </div>
}
export default FollowimageArea;