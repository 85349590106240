import axios from "axios";

/**
 * @param {string} query
 */
// @ts-ignore
export default async function featch(query, { variables } = {}) {
  try {
    const res = await axios({
      url: process.env.NEXT_PUBLIC_WORDPRESS_GRAPHQL_ENDPOINT,
      method: "post",
      data: {
        query,
        variables,
      },
    });
    const { data } = res.data;
    return data;
  } catch (error) {
    console.log("Failed to fetch API");
  }
}
