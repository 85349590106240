
import { useEffect, useState } from "react";
import { FollowCard, TagLoading } from "@src/component";
import { supabase } from "@src/lib/supabaseClient";
import getAuthorDetails from "@src/lib/wordpress/getAuthorDetails";
import { UserInterface } from "@src/utils/types";

const FollowArea = ({ user }: { user: UserInterface }) => {
  const [isFollowingLoading, setIsFollowingLoading] = useState(false)
  const [followersData, setfollowersData] = useState<any>([])

  const getFollowersList = async () => {
    setIsFollowingLoading(true);
    try {
      let { data: user_followers, error } = await supabase
        .from('user_followers')
        .select('*')
        .eq('follow_username', user?.username);
      if (error) {
        return;
      }

      if (user_followers) {
        const followersPromises = user_followers.map(async (userfollower) => {
          const userData = await getUserData(userfollower?.username);
          return userData;
        });

        const followersData = await Promise.all(followersPromises);
        setfollowersData(followersData);
        setIsFollowingLoading(false);

      } else {
        setIsFollowingLoading(false);
      }
    } catch (error) {
      setIsFollowingLoading(false);
    }
  }

  useEffect(() => {
    getFollowersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = async (username: string) => {
    let authorDetails = null;
    authorDetails = await getAuthorDetails({ slug: username });

    if (!authorDetails) {
      let { data: user_profile, error } = await supabase
        .from('user_profile')
        .select("*")
        .eq('username', username)
        .single();
      authorDetails = user_profile;
    } else {
      if (typeof authorDetails === "object") {
        authorDetails = { ...authorDetails, username: username, uri: `/author/${username}` }
      }
    }
    return authorDetails;
  }
  return (
    <div className="flex-1 py-6">
      {isFollowingLoading ? (
        <div className="flex flex-wrap gap-2">
          {Array(4)
            .fill("")
            .map((_, i) => (
              <div
                key={i}
                className="tagloading w-full rounded-md border border-border-light dark:border-border md:w-[calc(100%/2-0.5rem)] lg:w-[calc(100%/3-0.5rem)]"
              >
                <TagLoading />
              </div>
            ))}
        </div>
      ) : (
        <div className="flex flex-wrap gap-2">
          {followersData?.map((user: any) => (
            <FollowCard user={user} key={user.id} followUser={user} />
          ))}
        </div>
      )}
    </div>
  );
}
export default FollowArea;