import { supabase } from "../supabaseClient";

export const CommentOnPost = async (
    user_id: string,
    post_id: string,
    post_uri: string,
    content: string,
    comment_id: string
) => {
    try {
        const { data, error } = await supabase
            .from('post_comments')
            .insert({ user_id, post_id, post_uri, content, comment_id })
            .select();
    } catch (error) {
        console.log(error)
    }
};

export const LikeOnPost = async (
    user_id: string,
    post_id: string,
    post_uri: string,
    isLiked: boolean
) => {
    try {
        const { data: existingLikeData } = await supabase
            .from('post_like')
            .select()
            .eq('user_id', user_id)
            .eq('post_id', post_id);

        if (existingLikeData && existingLikeData.length > 0) {
            const existingRecord = existingLikeData[0];

            // console.log(existingRecord, "exits rec")

            // console.log(isLiked, "isLiked")

            const { data: updateData, error: updateError } = await supabase
                .from('post_like')
                .update({ isLiked })
                .eq('user_id', user_id)
                .eq('post_id', post_id)
                .select();

            console.log('Like updated:', updateData);
        } else {
            // Record doesn't exist, insert a new record
            const { data, error } = await supabase
                .from('post_like')
                .insert({ user_id, post_id, post_uri, isLiked })
                .select();

            console.log('New like inserted:', data);
        }
    } catch (error) {
        console.error('Error in LikeOnPost:', error);
    }
};

export const CountLikeOnPost = async (
    post_id: string,
    post_uri: string,
) => {
    let { data: post_like, error } = await supabase
        .from('post_like')
        .select("*")
        .eq('post_id', post_id)
    return post_like;
}

export const CountCommentsOnPost = async (
    post_id: string,
    post_uri: string,
) => {
    // Fetch comments for the post
    const { data: post_comments, error } = await supabase
        .from('post_comments')
        .select('*')
        .eq('post_id', post_id);

    if (error) {
        console.error('Error fetching comments:', error);
        return [];
    }

    for (let i = 0; i < (post_comments || []).length; i++) {
        const comment = post_comments[i];

        const { data: user, error: usererr } = await supabase
            .from('user_profile')
            .select('*')
            .eq('user_id', comment.user_id);

        if (!usererr) {
            comment.user = user ? user[0] || {} : {};
        }
    }

    // Return the modified comments or an empty array
    return post_comments || [];
};