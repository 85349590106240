import { TrendingUp } from "lucide-react";
import Link from "next/link";
import { type FC } from "react";
import { TrendingNavigation } from "../card";
import { TrendingTextLoading } from "../loading";
import { Divider, Trending } from "../miniComponent";
import AsideNavigation from "./AsideNavigation";
import { api } from "@src/utils/api";
import { DBCloundNewsSocials, asideItems } from "@src/utils/constants";
import { TrendingTagsTypes } from "@src/utils/context";
import AsideNews from "../miniComponent/AsideNews";

const Aside = () => {
  return (
    <div className="container-aside relative hidden min-h-[100dvh] py-4 lg:block">
      <aside className="">
        {/* <Navigations />
        <Divider />
        <TrendingComponent
          trendingItems={{ data: tagsData, isLoading: isLoading }}
        /> */}
        {/* <SocialHandles /> */}

        {/* <div className="w-4/12 px-4">
          <Divider />
        </div> */}

      <AsideNews />


        <div className="px-4 text-center">
          <span className="text-sm text-center text-gray-700 dark:text-text-primary">
            @{new Date().getFullYear()} Dynamic Business
          </span>
        </div>
      </aside>
    </div>
  );
};

export default Aside;

const Navigations = () => {
  return (
    <div className="pb-2">
      {asideItems.map((item, index) => {
        return item.type !== "link" ? (
          <AsideNavigation key={index} item={item} />
        ) : (
          <Link
            aria-label={`Go to ${item.name} Page`}
            href={item.href}
            key={index}
          >
            <AsideNavigation item={item} />
          </Link>
        );
      })}
    </div>
  );
};

const TrendingComponent: FC<{
  trendingItems: TrendingTagsTypes;
}> = ({ trendingItems }) => {
  return (
    <div className="px-4 py-3">
      <div className="mb-2 flex items-center justify-between">
        <h1 className="mb-2 text-sm font-semibold dark:text-text-primary ">
          Trending tags
        </h1>
        <span>
          <TrendingUp className="h-4 w-4 stroke-black dark:stroke-text-primary" />
        </span>
      </div>

      {trendingItems.isLoading ? (
        <>
          <TrendingTextLoading />
          <TrendingTextLoading />
          <TrendingTextLoading />
          <TrendingTextLoading />
        </>
      ) : trendingItems.data && trendingItems.data.length > 0 ? (
        trendingItems.data.map((item) => (
          <TrendingNavigation key={item.id} item={item} />
        ))
      ) : (
        <div className="h-24 flex justify-center items-center">
          <p className="text-sm text-gray-700 dark:text-text-primary">
            No trending tags
          </p>
        </div>
      )}
    </div>
  );
};

const SocialHandles = () => {
  return (
    <ul className="flex flex-wrap gap-2 px-4 justify-center pb-2">
      {DBCloundNewsSocials.map((item, index) => (
        <li key={index}>
          <a
            target="_blank"
            aria-label={`Follow us on ${item.name}`}
            title={`Follow us on ${item.name}`}
            className={`btn-social-icon flex h-8 w-8 transition-colors items-center justify-center ${item.name === "Twitter"
              ? "hover:bg-[#1da1f2]"
              : item.name === "Discord"
                ? "hover:bg-[#7289da]"
                : item.name === "Github"
                  ? "hover:bg-[#2c3646] github"
                  : item.name === "DB News Cloud"
                    ? "hover:bg-[#2c3646]"
                    : ""
              }`}
            href={`${item.link}`}
          >
            {item.icon}
          </a>
        </li>
      ))}
    </ul>
  );
};
