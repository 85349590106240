"use client";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Cookies from "js-cookie";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from 'next/navigation';
import React from "react";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";
import { articleimageDropdownList } from "@src/utils/constants";

const ArticleimageDropdown = React.forwardRef<HTMLDivElement>(({ }, ref) => {
  const { user } = useSupabaseAuth();
  const router = useRouter();
  const supabaseClient = useSupabaseClient();


  const logout = async () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    void await supabaseClient.auth.signOut();
  };

  return (
    <div
      ref={ref}
      className="absolute right-0 top-full z-20 mt-2 w-64 rounded-md border border-border-light bg-gray-50 py-2 text-left shadow-lg dark:border-border dark:bg-primary"
    >
      <Link href={`${user?.uri as string} `}>
        <div className="flex w-full cursor-pointer items-center gap-2 px-4 py-2 hover:bg-gray-200 dark:hover:bg-primary-light">
          <Image
            src={typeof user?.avatar === 'object' ? user?.avatar.url : user?.avatar || "/default_user.avif"}
            alt={user?.name as string}
            width={100}
            height={100}
            className="h-10 w-10 rounded-full object-cover"
            draggable={false}
          />
          <div>
            <h1 className="text-sm font-semibold dark:text-text-secondary">
              {user?.name}
            </h1>
            {/* <h2 className="text-sm text-gray-600 dark:text-text-primary">
              @{user?.user_metadata?.username}
            </h2> */}
          </div>
        </div>
      </Link>
      {
        articleimageDropdownList.map((item, index) => (
          item?.type ? (
            <div key={index} className="my-2 h-[1px] w-full bg-border-light dark:bg-border" />
          ) : <div className="cursor-pointer" onClick={() => {
            if (item.name === "Logout") void logout();
            if (item.link && user?.id) void router.push(item.link(user?.id))
          }} key={index}>
            <div className="flex items-center gap-2 px-4 py-2 hover:bg-gray-200 dark:hover:bg-primary-light cursor-pointer">
              {item.icon}
              <span className={`${item.danger ? "text-red" : "text-gray-700 dark:text-text-secondary"} text-sm font-normal`}>{item.name}</span>
            </div>
          </div>
        ))
      }
    </div>
  );
});

ArticleimageDropdown.displayName = "ArticleimageDropdown";

export default ArticleimageDropdown;
