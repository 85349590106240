"use client";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { Session, type User } from "@supabase/supabase-js";
import Cookies from "js-cookie";
import { Newspaper } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from 'next/navigation';
import React, { useContext, useEffect, useState } from "react";
// import { UserInterface } from "~/utils/SupabaseAuthContext";
import { imageDropdownList } from "@src/utils/constants";
import { C, type ContextValue } from "@src/utils/context";
import { UserInterface } from "@src/utils/types";

const imageDropdown = React.forwardRef<
  HTMLDivElement,
  {
    user: UserInterface | null;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>;
    signOut: () => Promise<void>;
  }
>(({ user, setOpened, signOut }, ref) => {
  const { setSearchOpen } = useContext(C) as ContextValue;
  const router = useRouter();

  const logout = async () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    await signOut();
  };

  const supabaseClient = useSupabaseClient()
  const [session, setSession] = useState<{ access_token: string, refresh_token: string } | null>(null)


  async function setToken() {
    const { data: session, error } = await supabaseClient.auth.getSession();
    error ? '' : setSession(session?.session);
  }

  useEffect(() => {
    if (!session) {
      setToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const segments = location.hostname.split('.');
  const isSubdomain = segments.length > Number(process.env.NEXT_PUBLIC_DOMAIN_LENGTH);

  return (
    <div
      ref={ref}
      className="absolute right-0 top-full z-20 mt-2 w-64 rounded-md border border-border-light bg-gray-50 py-2 text-left shadow-lg dark:border-border dark:bg-primary"
    >
      <Link href={`${user?.uri}`}>
        <div className="flex w-full cursor-pointer items-center gap-2 px-4 py-2 hover:bg-gray-200 dark:hover:bg-primary-light">
          <Image
            src={typeof user?.avatar === 'object' ? user?.avatar.url : user?.avatar || "/default_user.avif"}
            alt={user?.name || "user"}
            width={100}
            height={100}
            className="h-10 w-10 rounded-full object-cover"
            draggable={false}
          />
          <div>
            <h1 className="text-sm font-semibold dark:text-text-secondary">
              {user?.name}
            </h1>
            <h2 className="text-sm text-gray-600 dark:text-text-primary">
            </h2>
          </div>
        </div>
      </Link>

      <div className="my-2 h-[1px] w-full bg-border-light dark:bg-border" />

      {user ? (
        <div>
          <h1 className="mb-1 px-4 text-xs font-semibold text-gray-500 dark:text-text-primary">
            Personal Blogs
          </h1>
          <Link href={new URL(`http://${user.username}.${location.host}?access_token=${session?.access_token || ''}&refresh_token=${session?.refresh_token || ''}`)}>
            <div className="px-4 py-2 hover:bg-gray-200 hover:dark:bg-border">
              <h1 className="max-height-one text-sm text-gray-700 dark:text-text-secondary">
                {isSubdomain ? location?.host : `${user.username}.${location.host}`}
              </h1>
            </div>
          </Link>
        </div>
      ) : (
        <Link href={`/onboard/blog/setup?redirect=/`}>
          <div className="flex w-full cursor-pointer gap-2 px-4 py-2 hover:bg-light-bg dark:hover:bg-primary-light">
            <Newspaper className="h-7 w-7 fill-secondary" />
            <div>
              <h1 className="mb-1 text-sm font-semibold text-secondary">
                Start a personal blog
              </h1>
              <h1 className="text-xs font-medium text-gray-500 dark:text-text-secondary">
                Create a DB News Cloud blog for personal use. No collaborators.
              </h1>
            </div>
          </div>
        </Link>
      )}

      {
        imageDropdownList.map((item, index) => (
          <>
            {item?.type ? (
              <div key={index} className="my-2 h-[1px] w-full bg-border-light dark:bg-border" />
            ) : <div className={`${item?.hiddenItem ? "block lg:hidden" : ""} cursor-pointer`}
              onClick={() => {
                if (item?.danger) {
                  void logout();
                } else if (item?.link && user?.id) {
                  const link = item?.link();
                  void router.push(link);
                  setOpened(false);
                } else if (item?.onClick) {
                  item?.onClick(
                    setOpened,
                    setSearchOpen,
                  );
                }
              }}
              key={index}>
              <div className="flex w-full cursor-pointer items-center gap-2 px-4 py-2 text-gray-700 hover:bg-gray-200 dark:text-text-secondary dark:hover:bg-primary-light">
                <span>
                  {item?.icon}
                </span>
                <span className={`${item?.danger ? "text-red" : ""} text-sm font-medium`}>{item?.name || ''}</span>
              </div>
            </div>
            }
          </>
        ))
      }
    </div>
  );
});

imageDropdown.displayName = "imageDropdown";

export default imageDropdown;
