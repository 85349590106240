"use client";
import { createContext, useContext, useEffect, useState, type ReactNode } from 'react';
import { type Session } from '@supabase/supabase-js';
import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react';
import Cookies from 'js-cookie';
import { SupabaseAuthContextValue, UserInterface } from './types';
import { supabase as supabase2 } from './supabase2';

// Create a context for Supabase authentication
const SupabaseAuthContext = createContext<SupabaseAuthContextValue>({
    user: null,
    setUser: () => { },
    supabase2: null
});

// Custom hook to use the Supabase Auth context
export function useSupabaseAuth(): SupabaseAuthContextValue {
    return useContext(SupabaseAuthContext);
}

// Define the type for the props of the provider component
interface SupabaseAuthProviderProps {
    children: ReactNode;
}

// Provider component for the Supabase Auth context
const SupabaseAuthProvider: React.FC<SupabaseAuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<UserInterface | null>(null);
    const supabase = useSupabaseClient();
    // const userSession = useUser();

    //  console.log(userSession)

    async function updateState(session: Session | null) {
        if (!session) return;
        const { data: user, error } = await supabase.from('user_profile').select().eq('user_id', session?.user.id).single();
        session?.access_token && Cookies.set("access_token", session?.access_token || '');
        session?.refresh_token && Cookies.set("refresh_token", session?.refresh_token || '');
        setUser(user)
    }


    useEffect(() => {
        // Subscribe to auth state changes
        const { data: authListener } = supabase?.auth?.onAuthStateChange((event, session) => {
            if (event === "SIGNED_OUT") {
                return setUser(null);
            }
            updateState(session);
        });

        return () => {
            authListener.subscription.unsubscribe()
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <SupabaseAuthContext.Provider value={{ user, setUser, supabase2 }}>
            {children}
        </SupabaseAuthContext.Provider>
    );
};

export default SupabaseAuthProvider;
