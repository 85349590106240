import React, { type FC } from "react";
import {
  type NotificationDataTypes,
  type TrendingTagsTypes,
} from "@src/utils/context";
import { NewsCard } from "../card";


interface dataInterface {
  isLoading: boolean
  data: []
}

interface Props {
  loading: React.ReactNode;
  articleData?: { isLoading: boolean; data: any[] };
  tagsData?: TrendingTagsTypes;
  type: "TAG" | "ARTICLE" | "MINI_ARTICLES" | "NOTIFICATION";
  notificationData?: NotificationDataTypes;
  filter?: "This week" | "Any" | "This month" | "This year";
  error?: string | null;
}

interface postInterface {
  posts: any[],
  databaseId: string,
  name: string
}

const ManageData: FC<Props> = ({
  loading,
  articleData,
  type,
  error = null,
}) => {
  return (
    <div className="manageData-container w-full">
      {type === "ARTICLE" || type === "MINI_ARTICLES" ? (
        articleData?.isLoading ? (
          <>
            {Array(4)
              .fill("")
              .map((_, i) => (
                <div
                  key={i}
                  className="border-b border-border-light last:border-none dark:border-border"
                >
                  {loading}
                </div>
              ))}
          </>
        ) : articleData?.data && articleData?.data.length > 0 && error === null ? (
          articleData.data.map((item: postInterface, index) => {
            return (
              <div
                key={index}
                className="border-b border-border-light last:border-none articleCard dark:border-border"
              >
                {item && typeof item === 'object' && item.posts.map((newsData, index) => (
                  <div key={index} className="border-b border-border-light last:border-none articleCard dark:border-border">
                    <NewsCard card={newsData} category={item} />
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <div className="py-16">
            <p className="text-center text-lg text-gray-500 dark:text-gray-400">
              {error || "No Articles found! 😢"}
            </p>
          </div>
        )
      ) : null}
    </div>
  );
};

export default ManageData;
