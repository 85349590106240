
import { useSupabaseAuth } from '@src/utils/SupabaseAuthContext';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useRouter } from 'next/navigation';
import Cookies from "js-cookie";


const Account = () => {
  const { user } = useSupabaseAuth();
  const supabase = useSupabaseClient()
  const router = useRouter();

  const handleDelete = async () => {
    if (!user) return;
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    void await supabase.auth.signOut();
    await supabase.from('user_profile').delete().eq('id', user.id);
    void router.push("/");
  }
  
  return (
    <>
      <h1 className="text-2xl font-semibold text-red mb-4">Delete account</h1>
      <p className="text-lg text-gray-700 dark:text-text-secondary mb-6">
        Your personal data will be deleted permanently when you delete your account on DB News Cloud. This action is irreversible.
      </p>

      <button onClick={handleDelete} className="px-4 py-2 rounded-md bg-red hover:bg-[#f10707] text-white shadow-md outline-none font-medium text-sm">
        Delete your account
      </button>
    </>
  )
}

export default Account;