// import { useUser } from "@supabase/auth-helpers-react";
import { User } from "@supabase/supabase-js";
import React, { type FC } from "react";
import { Input } from "@src/component/miniComponent";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";

interface DataObject {
  name: string
  description: string
}

const BasicInfo: FC<{
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  data: DataObject;
}> = ({ handleChange, data }) => {

  // const user = useUser();
  const { user } = useSupabaseAuth();

  return (
    <div className="flex-1">
      <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-text-secondary">
        Basic Info
      </h1>

      <Input
        label="Full name"
        type="INPUT"
        variant="FILLED"
        placeholder="John Doe"
        input_type="text"
        disabled={false}
        required={true}
        value={Object.hasOwn(data, 'name') ? data.name : String(user?.name || '')}
        name="name"
        onChange={handleChange}
      />

      <Input
        label="Description (About you)"
        type="TEXTAREA"
        variant="FILLED"
        placeholder="I am a ..."
        input_type="text"
        disabled={false}
        required={false}
        value={Object.hasOwn(data, 'description') ? data.description : ''}
        max={500}
        name="description"
        onChange={handleChange}
      />
    </div>
  );
};

export default BasicInfo;
