"use client";
/* eslint-disable react-hooks/exhaustive-deps */
import { canUseLayoutEffect } from "@apollo/client/utilities";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input } from "@src/component/miniComponent";
import { supabase } from "@src/lib/supabaseClient";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";
import { api } from "@src/utils/api";
import { generalSettingsSocials } from "@src/utils/constants";

const dataFallBack = {
  name: "",
  about: "",
  handle: "",
  social: {
    twitter: "",
    mastodon: "",
    instagram: "",
    github: "",
    website: "",
    dbnews: "",
    youtube: "",
    dailydev: "",
  },
};

const General = () => {
  const { user } = useSupabaseAuth();
  const [generalData, setgeneralData] = useState([])
  const [data, setData] = useState<{
    name: string;
    about: string;
    handle: string;
    social: {
      twitter: string;
      mastodon: string;
      instagram: string;
      github: string;
      website: string;
      dbnews: string;
      youtube: string;
      dailydev: string;
    };
  }>(dataFallBack);

  const getGeneralaData = async () => {
    const user_id = user?.user_id;
    try {
      let { data: social_links, error } = await supabase
        .from('social_links')
        .select("*")
        .eq('user_id', user_id);
      setData((prev) => ({
        ...prev,
        social: social_links?.length && social_links[0] ? social_links[0] : {} as any,
      }) as any);
      setgeneralData(social_links as any);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getGeneralaData();
  }, []);


  // const { mutateAsync, isLoading } = api.handles.updateHandle.useMutation();
  const [isLoading, setisLoading] = useState(false)

  const handleUpdate = async () => {
    const social_links = data.social;
    const user_id = user?.user_id;
    setisLoading(true);
    try {
      const { data: updatedData, error } = await supabase
        .from('social_links')
        .update({
          github: social_links.github,
          dailydev: social_links.dailydev,
          dbnews: social_links.dbnews,
          instagram: social_links.instagram,
          mastodon: social_links.mastodon,
          twitter: social_links.twitter,
          website: social_links.website,
          youtube: social_links.youtube,
        })
        .eq('user_id', user_id).select()
      if (updatedData) {
        setisLoading(false)
        toast.success("Data updated successfully");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setisLoading(false);
    }
  };


  const addSocialLink = async () => {
    const social_links = data.social;
    const user_id = user?.user_id;
    setisLoading(true)
    try {
      const { data, error } = await supabase
        .from('social_links')
        .insert([
          {
            user_id: user_id,
            ...social_links,
          },
        ])
        .select();
      if (data) {
        toast.success("Social Links Added successfully");
        setisLoading(false)
      }
      setisLoading(false)
    } catch (error) {
      toast.error("Something went wrong");
      setisLoading(false)
    }
  };
  return (
    <>
      <h1 className="mb-6 text-2xl font-semibold text-gray-700 dark:text-text-secondary sm:mb-8 md:text-3xl lg:text-4xl">
        General Settings
      </h1>
      <div className="flex flex-wrap gap-4">
        {generalSettingsSocials.map((e) => (
          <div className="w-full md:w-[calc(100%/2-1rem)]" key={e.id}>
            <label htmlFor={e.name} className="flex items-center gap-2">
              {e.icon}
              <span className="text-base font-semibold text-gray-700 dark:text-text-primary">
                {e.label}
              </span>
            </label>
            <Input
              input_type="text"
              name={e.name}
              onChange={(event) => {
                setData({
                  ...data,
                  social: {
                    ...data.social,
                    [e.name]: event.target.value,
                  },
                });
              }}
              opacity={true}
              placeholder={e.placeholder}
              type="INPUT"
              value={data.social[e.name as keyof typeof data.social] || ''}
              required={false}
              variant="FILLED"
            />
          </div>
        ))}
      </div>
      {
        generalData.length ?
          <div className="w-full py-4">
            <button
              disabled={isLoading}
              onClick={() => void handleUpdate()}
              className={`btn-outline flex gap-2 ${isLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
            >
              {isLoading ? "Updating..." : "Update Social"}
            </button>
          </div>
          :
          <div className="w-full py-4">
            <button
              disabled={isLoading}
              onClick={() => void addSocialLink()}
              className={`btn-outline flex gap-2 ${isLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
            >
              {isLoading ? "adding..." : "Add Social"}
            </button>
          </div>
      }
    </>
  );
};

export default General;
