"use client";
import { useClickOutside } from "@mantine/hooks";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { TRPCClientError } from "@trpc/client";
import { AsyncResource } from "async_hooks";
import { MoreVertical, Tornado } from "lucide-react";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useEffect, useState, type FC } from "react";
import { toast } from "react-toastify";
import { supabase } from "@src/lib/supabaseClient";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";

import { api } from "@src/utils/api";
import { limitText } from "@src/utils/miniFunctions";


interface articleInterface {
  id: string;
  slug: string;
  title: string;
  content: string;
  cover_image: string | null;
  created_at: Date;
  read_time: number;
};

const Articles = () => {
  const { user } = useSupabaseAuth();
  const supabase = useSupabaseClient()


  const [type, setType] = useState<"PUBLISHED" | "DELETED">("PUBLISHED");


  const [data, setData] = useState<any[] | articleInterface[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [deletedData, setdeletedData] = useState<any>([])

  const fetchUserPosts = async () => {
    setIsLoading(true)
    try {
      let { data: post, error } = await supabase
        .from('post')
        .select("*")
        .eq('user_id', user?.user_id)
        .is("is_delete", null)
      setData(post === null ? [] : post);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false)
      toast.error("something went wrong")
    }
  }



  useEffect(() => { if (user) { fetchUserPosts() } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user])

  const gettemporaryDeltedData = async () => {
    setIsLoading(true)
    try {
      let { data: post, error } = await supabase
        .from('post')
        .select("*")
        .eq('is_delete', true)
      setIsLoading(false)
      setdeletedData(post?.length ? post : []) as any;
    } catch (error) {
      setIsLoading(false)
      toast.error("some thing went wrong")
    }
  }

  useEffect(() => {
    if (user) {
      gettemporaryDeltedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <section className="relative w-full">
      <header className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-gray-700 dark:text-text-secondary">
          {isLoading
            ? "Fetching your articles..."
            : `${type.charAt(0).toUpperCase() + type.slice(1)} (${type === "DELETED" ? deletedData.length : data?.length || 0
            })`}
        </h1>


        <div className="hidden md:flex items-center gap-2">
          {["PUBLISHED", "DELETED"].map((item) => (
            <button
              onClick={() =>
                setType(item as "PUBLISHED" | "DELETED")
              }
              key={item}
              className={`rounded-lg px-2 py-1 text-base font-medium  ${type === item ? "text-secondary bg-border-light dark:bg-border" : "text-gray-700 dark:text-text-secondary hover:bg-gray-100 dark:hover:bg-primary-light"
                }`}
            >
              {item}
            </button>
          ))}
        </div>
      </header>

      <main className="py-4">
        <header className="flex items-center gap-4 border-b border-border-light py-2 dark:border-border">
          <div className="flex flex-1">
            <h1 className="text-lg font-semibold text-gray-700 dark:text-text-secondary">
              Article
            </h1>
          </div>
          <div className="hidden sm:block">
            <h1 className="text-lg font-semibold text-gray-700 dark:text-text-secondary">
              Status
            </h1>
          </div>
          <div className="px-12"></div>
        </header>

        {isLoading ? (
          <>
            <ArticleLoading />
            <ArticleLoading />
            <ArticleLoading />
            <ArticleLoading />
          </>
        ) : data ? (
          type === "DELETED" ? (
            (
              deletedData.map((item: any) => (
                <ArticleCard type={type} data={item} key={item.id} />
              ))
            )
          ) : data.length > 0 ? (
            data.map((article) => (
              <ArticleCard key={article.id} type={type} data={article} />
            ))
          ) : (
            <div className="flex items-center justify-center px-4 py-8">
              <h1 className="text-3xl font-semibold text-gray-700 dark:text-text-secondary">
                No articles {type.slice(0, 1).toUpperCase() + type.slice(1).toLowerCase()} yet.
              </h1>
            </div>
          )
        ) : (
          <div className="flex items-center justify-center px-4 py-8">
            <h1 className="text-3xl font-semibold text-gray-700 dark:text-text-secondary">
              No articles {type.slice(0, 1).toUpperCase() + type.slice(1).toLowerCase()} yet.
            </h1>
          </div>
        )}
        {
          isLoading && (
            <>
              <ArticleLoading />
              <ArticleLoading />
              <ArticleLoading />
              <ArticleLoading />
              <ArticleLoading />
              <ArticleLoading />
            </>
          )
        }
      </main>
    </section>
  );
};
export default Articles;

const ArticleCard: FC<{
  data: {
    id: string;
    title: string;
    content: string;
    url: string;
    cover_image: string | null;
    created_at: Date;
  };
  type: "PUBLISHED" | "DELETED";
}> = ({ data, type }) => {
  const [opened, setOpened] = useState(false);
  const [control, setControl] = useState<HTMLDivElement | null>(null);
  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);

  const { user } = useSupabaseAuth();
  useClickOutside<HTMLDivElement>(() => setOpened(false), null, [
    control,
    dropdown,
  ]);

  // const { mutateAsync } = api.posts.restoreArticle.useMutation()
  // const { mutateAsync: deleteTemporarily } = api.posts.deleteTemporarily.useMutation();
  // const { mutateAsync: deletePermanently } = api.posts.deleteArticlePermantly.useMutation()

  // const restoreArticle = async () => {
  //   try {

  //     const res = await mutateAsync({
  //       slug: data.slug
  //     });

  //     if (res) {
  //       toast.success("Article restored successfully");
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   } catch (err) {
  //     if (err instanceof TRPCClientError) {
  //       toast.error(err.message)
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   }
  // }

  const deleteTemporarily = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from('post')
        .update({ is_delete: true })
        .eq('id', id)
        .select();

      if (error) {
        throw new Error(`Supabase error: ${error.message}`);
      }

      toast.success("Post is temporarily deleted successfully");
      await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 2 seconds (adjust the duration if needed)
      window.location.reload();
    } catch (err: any) {
      toast.error("Something went wrong, post was not deleted");
    }
  };


  const restoreDelete = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from('post')
        .update({ is_delete: null })
        .eq('id', id)
        .select();

      if (error) {
        throw new Error(`Supabase error: ${error.message}`);
      }

      toast.success("Post is Restored successfully");
      await new Promise(resolve => setTimeout(resolve, 3000));
      window.location.reload();
    } catch (err: any) {
      toast.error("Something went wrong, post was not Restored");
    }
  };

  return (
    <div className="flex items-center gap-4">
      {
        type === "DELETED" ?
          <div className="flex-1 py-3">
            <>
              <Link href={`/u/@${user?.username || ''}`}>
                <h1 className="max-height-one mb-1 text-lg md:text-xl font-semibold text-gray-700 dark:text-text-secondary">
                  {data?.title}
                </h1>
              </Link>
              <p className="text-sm md:text-base text-gray-500 dark:text-text-primary">
                {/* {data.read_time} min read - Published on{" "} */}
                <span className="font-semibold">
                  {new Date(data?.created_at).toDateString()}
                </span>
              </p>
            </>
          </div>
          :
          <div className="flex-1 py-3">
            <Link href={`/u/@${user?.username || ''}`}>
              <h1 className="max-height-one mb-1 text-lg md:text-xl font-semibold text-gray-700 dark:text-text-secondary">
                {limitText(data!.title, 100)}
              </h1>
            </Link>
            <p className="text-sm md:text-base text-gray-500 dark:text-text-primary">
              {/* {data.read_time} min read - Published on{" "} */}
              <span className="font-semibold">
                {new Date(data!.created_at).toDateString() as string}
              </span>
            </p>
          </div>
      }
      <div className="hidden sm:block">
        {
          type === "PUBLISHED" ? (
            <button
              type="button"
              className="cursor-default rounded-lg border border-border-light bg-slate-200 px-2 md:px-4 py-1 md:py-2 text-xs md:text-sm font-bold uppercase tracking-wider text-black dark:border-border"
            >
              PUBLISHED
            </button>
          ) : (
            <button
              type="button"
              className="cursor-default rounded-lg border border-border-light bg-slate-200 px-2 md:px-4 py-1 md:py-2 text-xs md:text-sm font-bold uppercase tracking-wider text-black dark:border-border"
            >
              DELETED
            </button>
          )
        }
      </div>

      <div className="relative px-6">
        <div
          onClick={() => setOpened((prev) => !prev)}
          ref={setControl}
          className="rounded-md border cursor-pointer border-border-light px-2 py-2 text-sm font-semibold uppercase hover:bg-light-bg dark:border-border dark:hover:bg-primary-light"
        >
          <MoreVertical className="h-4 w-4 fill-none stroke-gray-700 dark:stroke-text-secondary" />
        </div>

        {opened && (
          <div
            ref={setDropdown}
            className="absolute py-2 select-none z-10 right-6 top-full mt-2 w-44 rounded-md border border-border-light bg-light-bg shadow-md dark:border-border dark:bg-primary"
          >
            {
              type === "PUBLISHED" ? (
                <>
                  <Link href={`/article/edit/${data?.url as string}`} className="w-full block px-4 py-2 text-left text-gray-700 dark:text-text-secondary hover:bg-gray-200 dark:hover:bg-primary-light">
                    Edit
                  </Link>
                  <button onClick={() => deleteTemporarily(data?.id as string)}
                    className="w-full px-4 py-2 text-left hover:bg-gray-200 dark:hover:bg-primary-light">
                    <span className="text-red">Delete</span>
                  </button>
                </>
              ) : (
                <>
                  {/* <button onClick={() => void restoreArticle()} className="w-full px-4 py-2 text-left text-gray-700 dark:text-text-secondary hover:bg-gray-200 dark:hover:bg-primary-light">
                    Restore
                  </button> */}
                  <button onClick={() => restoreDelete(data.id)} className="text-red w-full px-4 py-2 text-left hover:bg-gray-200 dark:hover:bg-primary-light">
                    Restore Deleted
                  </button>
                </>
              )
            }
          </div>
        )}
      </div>
    </div >
  );
};

const ArticleLoading = () => {
  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex-1">
        <div className="loading mb-2 h-4 w-3/4 rounded-lg bg-border-light dark:bg-border"></div>
        <div className="loading h-4 w-1/2 rounded-lg bg-border-light dark:bg-border"></div>
      </div>
      <div className="flex items-center gap-2">
        <div className="loading h-8 w-32 rounded-lg bg-border-light dark:bg-border"></div>
        <div className="loading h-8 w-12 rounded-lg bg-border-light dark:bg-border"></div>
      </div>
    </div>
  );
};
