import React, { type FC } from "react";
import {
  type NotificationDataTypes,
  type TrendingArticleTypes,
  type TrendingTagsTypes,
} from "@src/utils/context";
import { TrendingCardList } from "../card";
import { Interface } from "readline";

interface InterfaceData {
  data: any[];
  isLoading: boolean;
}

interface Props {
  loading: React.ReactNode;
  articleData?: InterfaceData;
  tagsData?: TrendingTagsTypes;
  type: "TAG" | "ARTICLE" | "MINI_ARTICLES" | "NOTIFICATION";
  notificationData?: NotificationDataTypes;
  filter?: "This week" | "Any" | "This month" | "This year";
  error?: string | null;
}

const ManageData: FC<Props> = ({
  loading,
  articleData,
  type,
  error = null,
}) => {

  return (
    <div className="manageData-container w-full">
      {type === "ARTICLE" || type === "MINI_ARTICLES" ? (
        articleData?.isLoading ? (
          <>
            {Array(4)
              .fill("")
              .map((_, i) => (
                <div
                  key={i}
                  className="border-b border-border-light last:border-none dark:border-border"
                >
                  {loading}
                </div>
              ))}
          </>
        ) : articleData?.data && articleData?.data.length > 0 && error === null ? (
          articleData.data.map((item, index) => {
            return (
              <div
                key={index}
                className="border-b border-border-light last:border-none articleCard dark:border-border"
              >
                <TrendingCardList card={item} />
              </div>
            );
          })
        ) : (
          <div className="py-16">
            <p className="text-center text-lg text-gray-500 dark:text-gray-400">
              {error || "No Trending found! 😢"}
            </p>
          </div>
        )
      ) : null}
    </div>
  );
};

export default ManageData;
