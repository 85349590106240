/* eslint-disable react-hooks/exhaustive-deps */
import Link from "next/link";
import { FilterTimeOption } from "@src/hooks/useFilter";
import { api } from "@src/utils/api";
import { BookmarkLoading } from "../loading";
import ManageData from "./ManageData";
import { useCallback, useEffect, useState } from "react";
import getHomePageSlider from "@src/lib/wordpress/getHomePageSlider";
import { ManageTrendingData } from "@src/component/miniComponent";
import excludeCategories from "@src/lib/wordpress/excludeCategories";
import getPrefetchCategoryList from "@src/lib/wordpress/getPrefetchCategoryList";
import getPostsByCategory from "@src/lib/wordpress/getPostsByCategory";
import getFrontPageCategories from "@src/lib/wordpress/getFrontPageCategories";
import InfiniteScroll from "../InfiniteScroll";



// type Article = {
//   // Define the structure of your article here
//   id: number;
//   title: string;
//   // ... other fields
// };

const AsideNews = () => {

  const [articles, setArticles] = useState<any[]>([]);
  const [news, setNews] = useState<any[]>([]);
  const [excludeCat, setExcludeCat] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [initialPageInfo, setInitialPageInfo] = useState({
    hasNextPage: true,
    endCursor: "",
  });

  const fetchCategoryList = useCallback(async () => {
    const fetchCategoryNames = await getPrefetchCategoryList();
    const categories = fetchCategoryNames.split(",");
    setExcludeCat(await excludeCategories());
    const categoryList = await Promise.all(categories.map(async (category: any) => await getPostsByCategory({ slug: category })));
    const filteredCategoryList = categoryList.filter((item: any) => item);
    // console.log(filteredCategoryList)
    setArticles(filteredCategoryList);
    setIsFetching(false);
    setIsLoading(false);
  }, []);


  const handleLoadMore = async () => {
    setIsLoading(true)
    try {
      const { nodes, pageInfo } = await getFrontPageCategories(
        3,
        initialPageInfo.endCursor,
        excludeCat
      );
      setInitialPageInfo(pageInfo);
      setArticles([...articles, ...nodes]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => { fetchCategoryList(); }, [fetchCategoryList]);

  useEffect(() => {
    let posts: { date: string }[] = [];

    for (let index = 0; index < articles.length; index++) {
      const article = articles[index];
      if (article?.posts?.length) {
        posts = [...article?.posts, ...posts]
      }
    }
    console.log(posts, "post")
    posts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    setNews(posts)
  }, [articles]);


  return (
    <div className="mb-4 rounded-md border border-border-light bg-white p-4 dark:border-border dark:bg-primary">
      <header className="flex items-center justify-between border-b border-border-light pb-2 dark:border-border">
        <h1 className="text-xl font-bold text-gray-700 dark:text-text-secondary">
          Trending
        </h1>

        <Link href={"/explore/news"}>
          <button
            aria-label="view all the trending articles"
            role="button"
            className="btn-outline-small"
          >
            See all
          </button>
        </Link>
      </header>

      <div>
        <ManageTrendingData
          loading={<BookmarkLoading />}
          type="MINI_ARTICLES"
          error={news?.length === 0 ? "No Trending Articles" : null}
          articleData={{ isLoading: isFetching, data: news }}
        />
      </div>

      {!isFetching && <InfiniteScroll onScrollToEnd={handleLoadMore} isLoading={isLoading} />}

    </div>
  );
};

export default AsideNews;
