"use client";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useState } from "react";
import { MainBodyHeader } from "@src/component/header";
import { ArticleLoading } from "@src/component/loading";
import { ManageData } from "@src/component/miniComponent";
import { ManageAINewsData } from "@src/component/miniComponent";
import { type ArticleCard } from "@src/types";
import { api } from "@src/utils/api";
import { C, type ContextValue, type TrendingArticleTypes } from "@src/utils/context";

import getPrefetchCategoryList from "@src/lib/wordpress/getPrefetchCategoryList";
import excludeCategories from "@src/lib/wordpress/excludeCategories";
import getPostsByCategory from "@src/lib/wordpress/getPostsByCategory";
import getFrontPageCategories from "@src/lib/wordpress/getFrontPageCategories";
import InfiniteScroll from "@src/component/InfiniteScroll";
import { supabase } from "@src/utils/supabase2";

const MainBodyArticles = () => {

  const [articles, setArticles] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchData() }, [page]);


  const fetchData = async () => {
    setIsLoading(true);

    let { data, error } = await supabase
      .from('articles')
      .select('*')
      .eq('Publish', "true")
      .order('created_at', { ascending: false })
      .range((page - 1) * 25, page * 25 - 1);

    isFetching && setIsFetching(false);

    if (data && !error) {
      setArticles(prevArticles => [...prevArticles, ...data]);
    }

    setIsLoading(false);
    console.log(articles)
  }

  const handleLoadMore = async () => {
    console.log("handle more")
    setPage(prevPage => prevPage + 1);
  }
  // const [initialPageInfo, setInitialPageInfo] = useState({
  //   hasNextPage: true,
  //   endCursor: "",
  // });

  // const fetchCategoryList = useCallback(async () => {
  //   const fetchCategoryNames = await getPrefetchCategoryList();
  //   const categories = fetchCategoryNames.split(",");
  //   setExcludeCat(await excludeCategories());
  //   const categoryList = await Promise.all(categories.map(async (category: any) => await getPostsByCategory({ slug: category })));
  //   const filteredCategoryList = categoryList.filter((item: any) => item);
  //   // console.log(filteredCategoryList)
  //   setArticles(filteredCategoryList);
  //   setIsFetching(false);
  // }, []); // Dependencies array is empty, as the function does not depend on any external values


  // const handleLoadMore = async () => {
  //   setIsLoading(true)
  //   try {
  //     const { nodes, pageInfo } = await getFrontPageCategories(
  //       3,
  //       initialPageInfo.endCursor,
  //       excludeCat
  //     );
  //     setInitialPageInfo(pageInfo);
  //     setArticles([...articles, ...nodes]);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  // useEffect(() => { fetchCategoryList(); }, [fetchCategoryList]);


  return (
    <>
      <section className="container-main my-4 min-h-[] w-full overflow-hidden rounded-md border border-border-light bg-white dark:border-border dark:bg-primary" style={{ height: 'max-content' }}>
        <MainBodyHeader />

        {
          isFetching ? (
            Array.from({ length: 7 }).map((_, i) => (
              <ArticleLoading key={i} />
            ))
          ) :
            <ManageAINewsData
              loading={<ArticleLoading />}
              type="ARTICLE"
              error={null}
              articleData={{
                isLoading: isFetching,
                data: articles,
              }}
            />
        }
        {!isFetching && <InfiniteScroll onScrollToEnd={handleLoadMore} isLoading={isLoading} />}
      </section>
    </>
  );
};

export default MainBodyArticles;