"use client";
import { Menu } from "lucide-react";
import Link from "next/link";
import { useState } from "react";
import { Logo, LogonoText } from "@src/svgs";
import { HamburgerMenu } from "../aside";
import Image from "next/image";
import { useRouter } from "next/navigation";

const LeftArea = () => {
  const [menu, setMenu] = useState<boolean>(false);
  const { push } = useRouter();

  return (
    <div className="flex items-center justify-center gap-4">
      <button
        aria-label="menu icon"
        role="button"
        className="btn-icon flex h-10 w-10 lg:hidden"
        onClick={() => setMenu((prev) => !prev)}
      >
        <Menu className="h-5 w-5 stroke-black dark:stroke-white" />
      </button>

      <HamburgerMenu menu={menu} setMenu={setMenu} />

      {/* <Link aria-label="Go to Home Page" href={"/"}> */}

      <Image
        src={'/logo.png'}
        height={80}
        width={160}
        alt="logo"
        className="!cursor-pointer"
        onClick={() => push("/")}
      />
      {/* </Link> */}
    </div>
  );
};

export default LeftArea;
