"use client";
import { useState, type FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import z from "zod";
import { type SocialHandles, type UserDetails } from '@src/types';
import { api } from '@src/utils/api';
import { BasicInfo, SocialInfo } from '../macroComponent/Settings';
import { User } from '@supabase/supabase-js';
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';
import { useSupabaseAuth } from '@src/utils/SupabaseAuthContext';
import { UserInterface } from '@src/utils/types';

const Settings: FC<{ user: UserInterface | null }> = ({ user }) => {
  console.log("🚀 ~ user:", user)

  const supabase = useSupabaseClient();

  const { user: userSession, setUser } = useSupabaseAuth();

  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState({
    name: '',
    description: ''
  });

  useEffect(() => {
    if (userSession) {
      setData(prevData => ({ ...prevData, name: userSession.name, description: userSession.description }));
    }
  }, [userSession]);


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSocialChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setData({
  //     ...data,
  //     social: {
  //       ...data.social,
  //       [e.target.name]: e.target.value,
  //     },
  //   });
  // };

  // const updateHandler = async () => {
  //   const schema = z.object({
  //     name: z.string().min(3, "Name must be atleast 3 characters long"),
  //     username: z.string().min(3, "Username must be atleast 3 characters long"),
  //     email: z.string().email("Invalid Email"),
  //     location: z.string().nullable(),
  //     image: z.string().nullable(),
  //     tagline: z.string().nullable(),
  //     available: z.string().nullable(),
  //     cover_image: z.string().nullable(),
  //     bio: z.string().nullable(),
  //     skills: z.string().trim().nullable(),
  //     social: z.object({
  //       twitter: z.string(),
  //       instagram: z.string(),
  //       github: z.string(),
  //       stackoverflow: z.string(),
  //       facebook: z.string(),
  //       website: z.string(),
  //       linkedin: z.string(),
  //       youtube: z.string(),
  //     }),
  //   });

  //   try {
  //     const dataWithSocial = {
  //       ...data,
  //       social: {
  //         twitter: data.social.twitter || "",
  //         instagram: data.social.instagram || "",
  //         github: data.social.github || "",
  //         stackoverflow: data.social.stackoverflow || "",
  //         facebook: data.social.facebook || "",
  //         website: data.social.website || "",
  //         linkedin: data.social.linkedin || "",
  //         youtube: data.social.youtube || "",
  //       },
  //     };
  //     schema.parse(dataWithSocial);
  //     const socialHandles: (keyof SocialHandles)[] = [
  //       "twitter",
  //       "instagram",
  //       "github",
  //       "stackoverflow",
  //       "facebook",
  //       "website",
  //       "linkedin",
  //       "youtube",
  //     ];

  //     for (const handle of socialHandles) {
  //       const url = dataWithSocial.social[handle];
  //       if (url !== "") {
  //         try {
  //           z.string().url().parse(url);
  //         } catch (error) {
  //           if (error instanceof Error) {
  //             toast.error(`Invalid URL in ${handle}`);
  //             return;
  //           }
  //         }
  //       }
  //     }
  //     const res = await mutateAsync({
  //       ...dataWithSocial,
  //       skills: dataWithSocial.skills.trim().length > 0 ? dataWithSocial.skills.split(",").map((e) => e.trim()).filter(e => e !== "") : [],
  //       social: dataWithSocial.social,
  //     });

  //     toast.success(res.message);
  //     setData(JSON.parse(JSON.stringify({
  //       ...res.data[0],
  //       skills: (res.data[0]?.skills ?? []).join(", "),
  //     })) as UserDetails);
  //   } catch (error) {
  //     if (error instanceof z.ZodError && error.errors[0]) {
  //       toast.error(error.errors[0].message);
  //     }
  //   }
  // };

  const updateHandler = async () => {
    setIsLoading(true);
    if (data.name?.trim()?.length && data.description?.trim()?.length) {
      const { data: res, error } = await supabase.from('user_profile').update({ name: data?.name, description: data?.description })
        .eq('user_id', userSession?.user_id).select();
      if (!data || error) {
        toast.success("Try again");
        return;
      }
      setUser((prevData: any) => ({ ...prevData, name: data?.name, description: data?.description }));
      toast.success("updated successfully");
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="flex flex-col gap-8 md:flex-row">
        <BasicInfo handleChange={handleChange} data={data} />

        {/* <SocialInfo
          data={data}
          handleChange={handleChange}
          handleSocialChange={handleSocialChange}
        /> */}
      </div>

      <button
        disabled={isLoading}
        style={{
          opacity: isLoading ? 0.5 : 1,
        }}
        className={`${isLoading ? "cursor-not-allowed" : ""} btn-filled`}
        onClick={updateHandler}
      >
        {isLoading ? "Updating..." : "Update"}
      </button>
    </>
  )
}

export default Settings;