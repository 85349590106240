"use client";
import { useSession } from "next-auth/react";
import { useParams } from "next/navigation";
import { useContext, useEffect, useMemo, useState, type FC } from "react";
import { ArticleLoading, TagLoading } from "@src/component/loading";
import { ManageData, Select } from "@src/component/miniComponent";
import {
  FILTER_TIME_OPTIONS,
  FILTER_TIME_OPTIONS_LABEL,
  type FilterTimeOption,
} from "@src/hooks/useFilter";
import { api } from "@src/utils/api";
import {
  C,
  type ContextValue,
  type TrendingArticleTypes,
  type TrendingTagsTypes,
} from "@src/utils/context";
import ExploreMainComponentNavigation from "./ExploreMainComponentNavigation";
// import { fetchData } from "next-auth/client/_utils";
import getFeedsPosts from "@src/lib/wordpress/getLatestPosts";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";
import { postExist } from "@src/utils/types";
import getCategoryDetails from "@src/lib/wordpress/getCategoryDetails";
import getPaginatedPosts from "@src/lib/wordpress/getPaginatedPosts";

const useTrendingArticles = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const query = useParams();
  const slug = query?.slug

  async function fetchData() {
    setLoading(true)
    // const resData = await getFeedsPosts();
    const categoryName = slug ? slug : 'news';

    const categoryDetails = await getCategoryDetails(categoryName);
    if (categoryDetails === false) {
      setLoading(false);
      return setData([]);
    }

    const categoryPosts = await getPaginatedPosts({
      categoryDbId: categoryDetails?.databaseId,
      offset: 0,
      size: 20,
    });

    if (categoryPosts) {
      setData(categoryPosts?.posts as any);
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const trendingArticles = {
    data,
    isLoading: loading
  }

  return { trendingArticles }
}


const ExploreMainComponent = () => {
  const query = useParams();
  const slug = query?.slug
  const { user } = useSupabaseAuth();
  const { timeFilter } = useContext(C) as ContextValue;
  const { trendingArticles } = useTrendingArticles()

  const [data, setData] = useState<postExist[]>([])

  useMemo(() => {
    const currentDate = new Date();
    const filtered = trendingArticles?.data.filter((post: any) => {
      const postDate = new Date(post?.date);
      switch (timeFilter) {
        case 'WEEK':
          const oneWeekAgo = new Date(currentDate);
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
          return postDate >= oneWeekAgo;
        case 'MONTH':
          const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          return postDate >= firstDayOfMonth;
        case 'YEAR':
          const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
          return postDate >= firstDayOfYear;
        default:
          return true; // No filter
      }
    });
    setData(filtered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter])

  return (
    <section className="container-main my-4 min-h-[100dvh] w-full">
      <div className="mb-4 rounded-md border border-border-light bg-white px-4 py-12 dark:border-border dark:bg-primary md:px-6">
        <h1 className="mb-4 text-center text-3xl font-semibold text-gray-700 dark:text-text-secondary">
          Explore Tech articles & Tags
        </h1>
        <p className="mx-auto w-full text-center text-base font-normal text-gray-500 dark:text-text-primary md:w-10/12 lg:w-8/12">
        Explore the most popular tech articles from the DB News Cloud community. Stay updated with a constantly refreshing list of trending tags and insights from the brightest minds in tech.
        </p>
      </div>

      <div className="rounded-md border border-border-light bg-white pt-2 dark:border-border dark:bg-primary">
        <ExploreMainComponentNavigation slug={slug} />
        {
          <ExploreSection
            title="Trending Articles"
            type="ARTICLE"
            articlesData={{
              // data: data.length ? data : trendingArticles.data,
              data: trendingArticles.data,
              isLoading: trendingArticles.isLoading,
            }}
            showFilter={true}
          />
        }
      </div>
    </section>
  );
};

export default ExploreMainComponent;

const ExploreSection: FC<{
  articlesData?: { isLoading: boolean; data: postExist[] };
  tagsData?: TrendingTagsTypes;
  type: "TAG" | "ARTICLE";
  title: string;
  subtitle?: string;

  showFilter?: boolean;
}> = ({
  articlesData,
  tagsData,
  title,
  type,
  subtitle,
  showFilter = false,
}) => {
    console.log("🚀 ~ articlesData:", articlesData)
    const { setTimeFilter } = useContext(C) as ContextValue;
    return (
      <>
        <div className="flex items-center justify-between p-4 pb-0">
          <div className="mb-2">
            <h1 className="text-xl font-semibold text-gray-700 dark:text-text-secondary">
              {title}
            </h1>
            {subtitle && (
              <p className="text-base font-normal text-gray-500 dark:text-text-primary">
                {subtitle}
              </p>
            )}
          </div>
        </div>

        <div className="">
          <ManageData
            type={type}
            loading={type === "TAG" ? <TagLoading /> : <ArticleLoading />}
            articleData={articlesData}
            tagsData={tagsData}
          />
        </div>
      </>
    );
  };
