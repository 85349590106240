import React, { useState, useEffect } from 'react';

const InfiniteScroll = ({ onScrollToEnd, isLoading }: { onScrollToEnd: Function, isLoading: Boolean }) => {
  // const [isLoading, setIsLoading] = useState(false);

  // Function to check if the user has scrolled to the bottom
  const isAtBottom = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const totalHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    return scrollTop + windowHeight >= totalHeight - 100;
  };

  // Function to handle the scroll event
  const handleScroll = () => {
    if (isAtBottom() && !isLoading) {
      onScrollToEnd();
    }
  };

  // Attach the handleScroll function to the scroll event using useEffect
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScrollToEnd]); // Re-run the effect if the onScrollToEnd prop changes

  return (
    <div className="manageData-container w-full">
      {/* Loading ... */}
    </div>
  );
};

export default InfiniteScroll;
