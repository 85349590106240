import Link from "next/link";
import { FilterTimeOption } from "@src/hooks/useFilter";
import { api } from "@src/utils/api";
import { BookmarkLoading } from "../loading";
import ManageData from "./ManageData";
import { useCallback, useEffect, useState } from "react";
import getHomePageSlider from "@src/lib/wordpress/getHomePageSlider";
import { ManageTrendingData } from "@src/component/miniComponent";



// type Article = {
//   // Define the structure of your article here
//   id: number;
//   title: string;
//   // ... other fields
// };

const Trending = () => {
  // const { data: articlesData, isLoading } = api.posts.trendingArticles.useQuery(
  //   {
  //     variant: FilterTimeOption.any,
  //     limit: 4,
  //   },
  //   {
  //     retry: 0,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const [articlesData, setArticlesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Define the async function inside the useEffect
    async function fetchSliderPosts() {
      try {
        const sliderPosts = await getHomePageSlider(7);
        setArticlesData(sliderPosts);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch slider posts:', error);
      }
    }

    // Call the async function
    void fetchSliderPosts();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="mb-4 rounded-md border border-border-light bg-white p-4 dark:border-border dark:bg-primary">
      <header className="flex items-center justify-between border-b border-border-light pb-2 dark:border-border">
        <h1 className="text-xl font-bold text-gray-700 dark:text-text-secondary">
          Trending
        </h1>

        <Link href={"/explore/news"}>
          <button
            aria-label="view all the trending articles"
            role="button"
            className="btn-outline-small"
          >
            See all
          </button>
        </Link>
      </header>

      <div>
        <ManageTrendingData
          loading={<BookmarkLoading />}
          type="MINI_ARTICLES"
          error={articlesData?.length === 0 ? "No Trending Articles" : null}
          articleData={{ isLoading, data: articlesData }}
        />
      </div>
    </div>
  );
};

export default Trending;
