"use client";
// import { useUser } from "@supabase/auth-helpers-react";
import { BookOpen, Check, Plus, Settings } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useParams, useRouter } from "next/navigation";
import React, { useContext, useEffect, useState, type FC } from "react";
import { toast } from "react-toastify";
import { Balancer } from "react-wrap-balancer";
import removeMd from "remove-markdown";
import { StackedArticleLoading } from "@src/component/loading";
import { ArticleActions } from "@src/component/miniComponent";
import { CommentsModal } from "@src/component/popup";
import { CountCommentsOnPost } from "@src/lib/supabase/post";
import { extractUsername, setUserFollowingById } from "@src/lib/supabase/userFollowers";
// import { FollowContext } from "@src/pages/u/[username]/[slug]";
import type { Article, User } from "@src/types";
import { useSupabaseAuth } from "@src/utils/SupabaseAuthContext";
import { api } from "@src/utils/api";
import { formatDate } from "@src/utils/miniFunctions";
import { UserInterface } from "@src/utils/types";
import { FollowContext } from "@src/component/pages/singleArticle";
import { supabase } from "@src/utils/supabase2";

const ArticleBodyAI = ({ article }: { article: any }) => {
  // console.log("🚀 ~ ArticleBody ~ article:", article)

  // const [commentsModal, setCommentsModal] = useState(false);
  // const [commentsCount, setCommentsCount] = useState<any[]>([]);
  const [stories, setStories] = useState<any[]>([]);
  // const { mutate } = api.posts.read.useMutation();
  // const query = useParams();
  // const router = useRouter();
  // const username = query?.username

  // const fetchData = async () => {
  //   const resData = await CountCommentsOnPost(article.id, '');
  //   setCommentsCount(resData);
  // }


  const fetchStories = async () => {
    let { data: stories, error } = await supabase
      .from('articles')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(3)
    setStories(stories || []);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchStories() }, []);


  // useEffect(() => {
  //   if (commentsModal) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "unset";
  //   }
  // }, [commentsModal]);

  return (
    <main className="bg-white pb-12 dark:bg-primary">
      <div className="mx-auto max-w-[1000px]">
        {/* {article?.featuredImage?.sourceUrl && (
          <Image
            src={article?.featuredImage?.sourceUrl}
            alt={article?.title}
            width={1200}
            height={800}
            draggable={false}
            className="w-full md:w-10/12 lg:w-full mx-auto overflow-hidden md:rounded-b-md object-cover"
          />
        )} */}

        <section className={`relative pt-8 md:pb-0 md:pt-14`}>
          <div className="px-4">
            <div className="flex items-center w-full justify-center">
              <Balancer className="mx-auto block text-center mb-6 text-2xl sm:text-3xl font-bold leading-snug text-gray-700 dark:text-text-secondary w-full md:mb-8 md:text-5xl md:leading-tight">
                {article.title}
              </Balancer>
            </div>

            {/* {article?.subtitle && (
              <div className="flex items-center w-full justify-center">
                <Balancer className="mx-auto mb-5 break-words text-center text-xl font-normal text-gray-600 dark:text-text-primary md:mb-10 md:text-3xl">
                  {article?.subtitle}
                </Balancer>
              </div>
            )} */}

            <div className="mx-auto mb-6 flex w-full flex-col items-center justify-center gap-2 md:mb-10 md:w-fit lg:flex-row">
              {/* <div
                aria-label="Visit image"
                className="mb-10 flex items-center gap-2 lg:mb-0"
              >
                <Image
                  src={"/user-default.webp"}
                  alt={article?.author || "Jeremy"}
                  width={70}
                  height={70}
                  draggable={false}
                  className="h-8 w-8 overflow-hidden rounded-full object-cover"
                />

                <h1 className="text-xl font-semibold text-gray-700 dark:text-text-secondary">
                  {article?.author || "Jeremy"}
                </h1>
              </div> */}

              <div className="flex w-full items-center justify-between gap-2 lg:w-fit">
                {/* <span className="hidden text-gray-900 dark:text-text-secondary lg:block">
                  ·
                </span> */}
                <h3 className="text-base font-medium text-gray-700 dark:text-text-primary md:text-lg">
                  {formatDate(new Date(article.created_at))}
                </h3>
                <span className="hidden text-gray-900 dark:text-text-secondary lg:block">
                  ·
                </span>
                <div className="flex items-center gap-2">
                  <BookOpen className="h-5 w-5 stroke-gray-700 dark:stroke-text-secondary" />
                  {/* <span className="text-base text-gray-700 dark:text-text-primary md:text-lg">
                    {article.read_time} min read
                  </span> */}
                </div>
              </div>
            </div>

            <div
              dangerouslySetInnerHTML={{ __html: article.content || "" }}
              className="article mx-auto w-full break-words text-gray-700 dark:text-text-secondary pb-10 pt-2 sm:pt-6 md:py-6"
            />
          </div>

          {/* <ArticleActions
            article={article}
            setCommentsModal={setCommentsModal}
            commentsCount={commentsCount}
          /> */}

          {/* <ArticleTags tags={article.tags} /> */}

          <div className="flex flex-wrap md:flex-nowrap gap-10 w-[100%] p-4">
            {stories.map((story, index) => (
              <div key={index} className="rounded-md border border-border-light bg-white p-4 dark:border-border dark:bg-primary md:w-[30%] w-[100%]">
                <Link href={`/news/${story.id}`}>
                  <h5 className="max-height-three mb-2 font-semibold text-gray-700 dark:text-text-secondary mb-3">
                    {story.title}
                  </h5>
                  <p className="max-height-four break-words text-sm text-gray-500 dark:text-text-primary">
                    {story.summary}
                  </p>
                </Link>
              </div>
            ))}
          </div>


          {/* <ArticleAuthor author={article?.author} /> */}

          {/* {commentsModal && (
            <CommentsModal
              id={article.id}
              commentsModal={commentsModal}
              authorUsername={article.author.username}
              setCommentsModal={setCommentsModal}
            />
          )} */}
          {/*
          {article.series && (
            <SeriesSection series={article.series} slug={article.slug} />
          )} */}
        </section>
      </div>
    </main>
  );
};

export default ArticleBodyAI;


const ArticleAuthor = ({ author }: { author: any }) => {
  return (
    <div className="px-4">
      <div className="mx-auto mb-4 mt-10 w-full border-y border-border-light px-4 py-6 dark:border-border md:w-8/12">
        <div className="flex flex-1 items-start gap-4">
          {/* <Link href={author?.uri || ''}> */}
          <Image
            src={"/user-default.webp"}
            alt={author || "Jeremy"}
            width={100}
            height={100}
            className="obejct-cover md:h-18 md:w-18 h-14 w-14 overflow-hidden rounded-full sm:h-16 sm:w-16 "
          />
          {/* </Link> */}

          <div className={"flex-1"}>
            <div className="flex flex-col sm:flex-row w-full items-start justify-between">
              <div className="mb-3 sm:mb-0">
                <h2 className="text-uppercase mb-1 text-sm font-medium text-gray-600 dark:text-text-primary">
                  WRITTEN BY
                </h2>
                {/* <Link href={author?.uri}> */}
                <h1 className="text-uppercase text-lg font-semibold text-gray-800 dark:text-text-secondary">
                  {author || "Jeremy"}
                </h1>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
